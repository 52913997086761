var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PublicFrame',[_c('v-layout',{attrs:{"align-center":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"blue"}},[_c('v-toolbar-title',[_vm._v("ログイン")])],1),_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"userId","label":"ユーザーID","id":"userId","type":"text","error-messages":errors,"success":valid},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"password","label":"パスワード","id":"password","type":"password","error-messages":errors,"success":valid},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"blue","id":"btnLogin","disabled":invalid || !validated},on:{"click":function($event){return passes(_vm.onClickLogin)}}},[_vm._v("Login")])],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"type":"warning","dense":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)]}}])}),_c('br'),_c('div',{staticClass:"caption"},[_vm._v(" アカウントが無い場合は"),_c('router-link',{attrs:{"to":"/signup"}},[_vm._v("新規登録")]),_vm._v("をお願いします。 ")],1),_c('div',{staticClass:"caption"},[_vm._v(" パスワードを忘れた方は"),_c('a',{on:{"click":_vm.onClickShowPasswordChangeForm}},[_vm._v("こちら")]),_vm._v("から変更してください。 ")]),_c('div',{staticClass:"caption"},[_vm._v(" お問い合わせは"),_c('a',{on:{"click":_vm.onClickShowContactForm}},[_vm._v("こちら")]),_vm._v("からお願いします。 ")]),_c('PasswordChangeForm',{attrs:{"showDialog":_vm.showPasswordChangeForm,"showType":"request"},on:{"clickClose":function($event){_vm.showPasswordChangeForm = false;}}}),_c('ContactForm',{attrs:{"showDialog":_vm.showContactForm},on:{"clickClose":function($event){_vm.showContactForm = false;}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }