import { Vue, Component } from "vue-property-decorator";
import BaseFrame from "@/components/BaseFrame.vue";
import { logger } from "@/logger";
import { AuthModule } from "@/store/module/auth";
import PasswordChangeForm from "@/components/passwordChangeForm/PasswordChangeForm.vue";

@Component({ components: { BaseFrame, PasswordChangeForm } })
/**
 * トップ画面
 */
export default class Mypage extends Vue {
  dialogWithdrawal = false;
  showPasswordChangeForm = false;

  /**
   * ユーザーID
   */
  get userId(): string {
    return AuthModule.userId;
  }

  /**
   * 退会ボタンクリック時の処理
   */
  onClickWithdrawal(): void {
    logger.trace("onClickWithdrawal");
    this.$store.dispatch("auth/leave").then(() => {
      this.dialogWithdrawal = false;
      this.$router.go(0);
    });
  }
}
