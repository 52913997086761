<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="blue">
            <v-toolbar-title>サインアップ完了通知</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            認証用のURLをメールに送りました。<br/>
            URLにアクセスして認証を行ってください。<br/>
            認証が終わってから<router-link to="/login">ログイン</router-link>をお願いします。
          </v-card-text>
        </v-card>
      <br/>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AuthenticationGuidance',
};
</script>
