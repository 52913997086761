import { Vue, Component } from "vue-property-decorator";
import BaseFrame from "@/components/BaseFrame.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { KeyValueItem } from "@/model/keyValueItem";
import { KeyValuesModule } from "@/store/module/keyValues";
import { ApiKeysModule } from "@/store/module/apiKeys";
import ApiKeys from "@/components/apiKeys/ApiKeys.vue";
import { logger } from "@/logger";

@Component({ components: { BaseFrame, ApiKeys, ValidationObserver, ValidationProvider } })
/**
 * キーバリュー管理
 */
export default class KeyValues extends Vue {
  DLG_STATE = {
    NEW: 1,
    EDIT: 2,
    DETAIL: 3,
  };
  loading = false;
  dialog = false;
  saveButton = false;
  headers = [
    { text: "Key", value: "key", align: "start" },
    { text: "Value", value: "value" },
    { text: "UpdatedAt", value: "updatedAtStr" },
    { text: "Actions", value: "action", sortable: false },
  ];
  apiKey = "";
  editedIndex = -1;
  editedItem: KeyValueItem = { apiKey: "", key: "", value: "", registeredAtStr: "", updatedAtStr: "", updatedAt: 0 };
  defaultItem: KeyValueItem = { apiKey: "", key: "", value: "", registeredAtStr: "", updatedAtStr: "", updatedAt: 0 };

  /**
   * タイトル
   * @return {string}
   */
  get formTitle(): string {
    return this.editedIndex === -1 ? "新規" : this.saveButton ? "編集" : "詳細";
  }

  /**
   * ダイアログのステート
   * @return {number}
   */
  get dialogState(): number {
    logger.debug(`called dialogState() with ${this.editedIndex} ${this.saveButton}`);
    return this.editedIndex === -1 ? this.DLG_STATE.NEW : this.saveButton ? this.DLG_STATE.EDIT : this.DLG_STATE.DETAIL;
  }

  /**
   * テーブルに表示するitems
   */
  get items(): Array<KeyValueItem> {
    return KeyValuesModule.items;
  }

  /**
   * APIキー一覧
   */
  get apiKeys(): string[] {
    return ApiKeysModule.items.reduce((prev, cur) => {
      if (cur.service === "KeyValue") {
        prev.push(cur.apiKey);
      }
      return prev;
    }, new Array<string>());
  }

  /**
   * コンポーネントが作成された時の処理
   */
  created(): void {
    // this.$store.dispatch("apiKeys/getList");
  }

  /**
   * APIキーを変更した時の処理
   * @param {string} selectedApiKey
   */
  onChangeApiKey(selectedApiKey: string): void {
    if (selectedApiKey) {
      this.getList(selectedApiKey);
    }
    this.apiKey = selectedApiKey;
  }

  /**
   * リストを取得する
   * @param {string} apiKey
   */
  getList(apiKey: string): void {
    this.loading = true;
    this.$store.dispatch("keyValues/getList", apiKey).finally(() => {
      this.loading = false;
    });
  }

  /**
   * newをクリックした時の処理
   */
  onNewClick(): void {
    const newItem = Object.assign({}, this.defaultItem);
    newItem.apiKey = this.apiKey;
    this.editedItem = newItem;
    // newItem.registeredAtStr = newItem.updatedAtStr = moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ssZ");
    this.dialog = true;
    this.saveButton = true;
  }

  /**
   * 表示
   * @param {KeyValueItem} item
   */
  showItem(item: KeyValueItem): void {
    this.$store.dispatch("keyValues/getKeyValue", { apiKey: this.apiKey, key: item.key }).then(() => {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = KeyValuesModule.specificItem;
      this.editedItem.apiKey = this.apiKey;
      this.dialog = true;
      this.saveButton = false;
    });
  }

  /**
   * 編集
   * @param {KeyValueItem} item
   */
  editItem(item: KeyValueItem): void {
    // this.call(item, this.asyncFuncShowDialog, true);
    this.$store.dispatch("keyValues/getKeyValue", { apiKey: this.apiKey, key: item.key }).then(() => {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = KeyValuesModule.specificItem;
      this.editedItem.apiKey = this.apiKey;
      logger.trace("editItem", KeyValuesModule.specificItem);
      this.dialog = true;
      this.saveButton = true;
    });
  }

  /**
   * 削除
   * @param {KeyValueItem} item
   */
  deleteItem(item: KeyValueItem): void {
    if (confirm("Are you sure you want to delete this item?")) {
      this.$store.dispatch("keyValues/deleteKeyValue", { apiKey: this.apiKey, key: item.key }).then(() => {
        this.getList(this.apiKey);
      });
    }
  }

  /**
   * ダイアログを閉じる
   */
  close(): void {
    this.dialog = false;
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obs: any = this.$refs.obs;
      if (obs && "reset" in obs) {
        obs.reset();
      }
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 300);
  }

  /**
   * 保存する
   */
  save(): void {
    if (this.editedIndex > -1) {
      this.$store.dispatch("keyValues/updateKeyValue", this.editedItem).then(() => {
        this.getList(this.apiKey);
      });
    } else {
      this.$store.dispatch("keyValues/createKeyValue", this.editedItem).then(() => {
        this.getList(this.apiKey);
      });
    }
    this.close();
  }
}
