import { Vue, Component, Watch } from "vue-property-decorator";
import BaseFrame from "@/components/BaseFrame.vue";
import { ApiKeysItem } from "@/model/apiKeysItem";
import { ApiKeysModule } from "@/store/module/apiKeys";
import { AuthModule } from "@/store/module/auth";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ApiKeysPostRequest, ApiKeysSearchCondition } from "generated/openapi-generator";
import { logger } from "@/logger";

type ApiKeysDialogInfo = ApiKeysItem & { autoGenerateApiKey: boolean; autoGenerateSecretKey: boolean };

@Component({ components: { BaseFrame, ValidationObserver, ValidationProvider } })
/**
 * APIキー管理画面
 */
export default class ApiKeys extends Vue {
  DLG_STATE = {
    NEW: 1,
    EDIT: 2,
    DETAIL: 3,
  };
  dialog = false;
  saveButton = false;
  loading = true;
  apikeys = [];
  selected = new Array<unknown>();
  toggleSearchCondition = false;
  searchCondition: ApiKeysSearchCondition = {
    userId: undefined,
  };
  editedIndex = -1;
  editedItem: ApiKeysDialogInfo = {
    name: "",
    apiKey: "",
    service: "",
    secretKey: "",
    userId: "",
    updatedAt: 0,
    updatedAtStr: "",
    registeredAt: 0,
    registeredAtStr: "",
    autoGenerateApiKey: true,
    autoGenerateSecretKey: true,
  };
  defaultItem: ApiKeysDialogInfo = {
    name: "",
    apiKey: "",
    service: "",
    secretKey: "",
    userId: "",
    updatedAt: 0,
    updatedAtStr: "",
    registeredAt: 0,
    registeredAtStr: "",
    autoGenerateApiKey: true,
    autoGenerateSecretKey: true,
  };

  /**
   * 管理者かどうか
   */
  get isAdmin(): boolean {
    return AuthModule.isAdmin;
  }

  /**
   * ヘッダー
   */
  get headers(): Array<{ text: string; value: string }> {
    const headers = [
      { text: "Name", value: "name" },
      { text: "ApiKey", value: "apiKey" },
      { text: "Service", value: "service" },
      { text: "Action", value: "action", sortable: false },
    ];
    if (this.isAdmin) {
      headers.unshift({ text: "UserId", value: "userId" });
    }

    return headers;
  }

  /**
   * タイトル
   * @return {string}
   */
  get formTitle(): string {
    return this.editedIndex === -1 ? "新規" : this.saveButton ? "編集" : "詳細";
  }

  /**
   * ダイアログのステート
   * @return {number}
   */
  get dialogState(): number {
    logger.debug(`called dialogState() with ${this.editedIndex} ${this.saveButton}`);
    return this.editedIndex === -1 ? this.DLG_STATE.NEW : this.saveButton ? this.DLG_STATE.EDIT : this.DLG_STATE.DETAIL;
  }

  /**
   * テーブルに表示するitems
   */
  get items(): Array<ApiKeysItem> {
    return ApiKeysModule.items;
  }

  /**
   * マウント時
   */
  mounted(): void {
    this.getList();
  }

  /**
   * リストを取得する
   */
  getList(): void {
    this.loading = true;
    this.$store.dispatch("apiKeys/getList", this.searchCondition).finally(() => {
      this.loading = false;
    });
  }

  /**
   * 検索条件をクリックした時の処理
   */
  onSearchConditionClick(): void {
    this.toggleSearchCondition = !this.toggleSearchCondition;
    if (this.toggleSearchCondition) {
      this.$nextTick(() => (this.$refs.refSearchConditionUserId as HTMLElement).focus());
    }
  }

  /**
   * 新規作成をクリックした時の処理
   */
  onNewClick(): void {
    const newItem = Object.assign({}, this.defaultItem);
    if (this.isAdmin) {
      if (this.searchCondition.userId) {
        newItem.userId = this.searchCondition.userId;
      }
    } else {
      newItem.userId = AuthModule.userId;
    }
    this.editedItem = newItem;
    this.dialog = true;
    this.saveButton = true;
  }

  /**
   * 表示
   * @param {KeyValueItem} item
   */
  showItem(item: ApiKeysItem): void {
    this.$store.dispatch("apiKeys/getApiKey", item).finally(() => {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign(ApiKeysModule.specificItem, { autoGenerateApiKey: false, autoGenerateSecretKey: false });
      this.dialog = true;
      this.saveButton = false;
    });
  }

  /**
   * 編集
   * @param {ApiKeysItem} item
   */
  editItem(item: ApiKeysItem): void {
    // this.call(item, this.asyncFuncShowDialog, true);
    this.$store.dispatch("apiKeys/getApiKey", item).then(() => {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign(ApiKeysModule.specificItem, { autoGenerateApiKey: false, autoGenerateSecretKey: false });
      logger.trace("editItem", ApiKeysModule.specificItem);
      this.dialog = true;
      this.saveButton = true;
    });
  }

  /**
   * 削除
   * @param {ApiKeysItem} item
   */
  deleteItem(item: ApiKeysItem): void {
    if (confirm("Are you sure you want to delete this item?")) {
      this.$store.dispatch("apiKeys/deleteApiKey", item.apiKey).then(() => {
        this.getList();
      });
    }
  }

  /**
   * 選択が変更された時
   */
  @Watch("selected")
  onChangeSelected(): void {
    let selectedApiKey = "";
    if (this.selected.length === 1) {
      if (hasApiKey(this.selected[0])) {
        selectedApiKey = this.selected[0].apiKey;
      }
    }
    this.$emit("change-selected", selectedApiKey);
  }

  /**
   * 検索条件をリセットする
   */
  resetSearchCondition(): void {
    this.searchCondition.userId = undefined;
    this.getList();
  }

  /**
   * ダイアログを閉じる
   */
  close(): void {
    this.dialog = false;
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obs: any = this.$refs.obs;
      if (obs && "reset" in obs) {
        obs.reset();
      }
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 300);
  }

  /**
   * 保存する
   */
  save(): void {
    this.editedItem.service = "KeyValue";
    if (this.editedIndex > -1) {
      const apiKeysItem: ApiKeysItem = {
        name: this.editedItem.name,
        apiKey: this.editedItem.apiKey,
        secretKey: this.editedItem.autoGenerateSecretKey ? "" : this.editedItem.secretKey,
        userId: this.editedItem.userId,
        service: this.editedItem.service,
        registeredAt: this.editedItem.registeredAt,
        registeredAtStr: this.editedItem.registeredAtStr,
        updatedAt: this.editedItem.updatedAt,
        updatedAtStr: this.editedItem.updatedAtStr,
      };
      this.$store.dispatch("apiKeys/updateApiKey", apiKeysItem).then(() => {
        this.getList();
      });
    } else {
      const apiKeysPostRequest: ApiKeysPostRequest = {
        name: this.editedItem.name,
        apiKey: this.editedItem.autoGenerateApiKey ? "" : this.editedItem.apiKey,
        secretKey: this.editedItem.autoGenerateSecretKey ? "" : this.editedItem.secretKey,
        service: this.editedItem.service,
        userId: this.editedItem.userId,
      };
      this.$store.dispatch("apiKeys/createApiKey", apiKeysPostRequest).then(() => {
        this.getList();
      });
    }
    this.close();
  }
}

const hasApiKey = (v: unknown): v is { apiKey: string } => {
  return typeof v === "object" && !!v && "apiKey" in v;
};
