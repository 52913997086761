var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600px","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("パスワード変更フォーム")])]),_c('v-card-text',[(_vm.showType === 'request')?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"ユーザーID","error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"メールアドレス","error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showType === 'changeWithToken')?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"変更後のパスワード","error-messages":errors,"success":valid,"type":"password","outlined":""},model:{value:(_vm.passwordAfter),callback:function ($$v) {_vm.passwordAfter=$$v},expression:"passwordAfter"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32|password:@password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"変更後のパスワード（確認）","error-messages":errors,"success":valid,"type":"password","outlined":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showType === 'changeFromMypage')?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"変更前のパスワード","error-messages":errors,"success":valid,"type":"password","outlined":""},model:{value:(_vm.passwordBefore),callback:function ($$v) {_vm.passwordBefore=$$v},expression:"passwordBefore"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"変更後のパスワード","error-messages":errors,"success":valid,"type":"password","outlined":""},model:{value:(_vm.passwordAfter),callback:function ($$v) {_vm.passwordAfter=$$v},expression:"passwordAfter"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32|password:@password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"変更後のパスワード（確認）","error-messages":errors,"success":valid,"type":"password","outlined":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.onClickCancel}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"green darken-1","disabled":invalid,"text":""},on:{"click":_vm.onClickSend}},[_vm._v("送信")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }