<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>sapislab管理画面</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="onClickHelp" icon>
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <slot/>
    </v-main>

    <v-footer app>
      <span>&copy; miya15 2021</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'PublicFrame',
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    onClickHelp() {
      window.open(process.env.VUE_APP_DOCUMENT_SITE, '_blank');
    },
  }
};
</script>
