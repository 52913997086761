import { Vue, Component } from "vue-property-decorator";
import PrivacyPolicy from "@/components/privacyPolicy/PrivacyPolicy.vue";

@Component({ components: { PrivacyPolicy } })
/**
 * 利用規約
 */
export default class TermsOfService extends Vue {
  dialog = false;

  /**
   * 同意しないボタンクリック時の処理
   */
  onClickDisagree(): void {
    this.dialog = false;
    this.$emit("onChangeAgree", false);
  }

  /**
   * 同意するボタンクリック時の処理
   */
  onClickAgree(): void {
    this.dialog = false;
    this.$emit("onChangeAgree", true);
  }
}
