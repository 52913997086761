/* tslint:disable */
/* eslint-disable */
/**
 * management portal
 * management portal
 *
 * The version of the OpenAPI document: 1.0
 * Contact: ks7u4rw2@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKeysGetResponse } from '../model';
// @ts-ignore
import { ApiKeysPostRequest } from '../model';
// @ts-ignore
import { ApiKeysPutRequest } from '../model';
// @ts-ignore
import { ContactPostRequest } from '../model';
// @ts-ignore
import { ErrorInfoList } from '../model';
// @ts-ignore
import { KeyValuesGetResponse } from '../model';
// @ts-ignore
import { KeyValuesPostRequest } from '../model';
// @ts-ignore
import { KeyValuesPutRequest } from '../model';
/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * APIキー削除
         * @summary APIキー削除
         * @param {string} apiKey APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeysApiKey: async (apiKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            if (apiKey === null || apiKey === undefined) {
                throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteApiKeysApiKey.');
            }
            const localVarPath = `/apiKeys/{apiKey}`
                .replace(`{${"apiKey"}}`, encodeURIComponent(String(apiKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * キーバリュー削除
         * @summary キーバリュー削除
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKeyValuesKey: async (key: string, xAPIKEY: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling deleteKeyValuesKey.');
            }
            // verify required parameter 'xAPIKEY' is not null or undefined
            if (xAPIKEY === null || xAPIKEY === undefined) {
                throw new RequiredError('xAPIKEY','Required parameter xAPIKEY was null or undefined when calling deleteKeyValuesKey.');
            }
            const localVarPath = `/keyValues/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * APIキー一覧取得
         * @summary APIキー一覧
         * @param {string} [userId] ユーザーID（管理者のみ指定可能）
         * @param {number} [page] ページ番号
         * @param {number} [num] 1ページのデータ数
         * @param {string} [orders] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys: async (userId?: string, page?: number, num?: number, orders?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (orders !== undefined) {
                localVarQueryParameter['orders'] = orders;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * APIキー詳細取得
         * @summary APIキー詳細
         * @param {string} apiKey APIキー
         * @param {string} [userId] ユーザーID（管理者のみ指定）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeysApiKey: async (apiKey: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            if (apiKey === null || apiKey === undefined) {
                throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getApiKeysApiKey.');
            }
            const localVarPath = `/apiKeys/{apiKey}`
                .replace(`{${"apiKey"}}`, encodeURIComponent(String(apiKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ログアウトする
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthLogout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * キーバリュー一覧取得
         * @summary キーバリュー一覧
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyValues: async (xAPIKEY: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAPIKEY' is not null or undefined
            if (xAPIKEY === null || xAPIKEY === undefined) {
                throw new RequiredError('xAPIKEY','Required parameter xAPIKEY was null or undefined when calling getKeyValues.');
            }
            const localVarPath = `/keyValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * キーバリュー詳細取得
         * @summary キーバリュー詳細
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyValuesKey: async (key: string, xAPIKEY: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getKeyValuesKey.');
            }
            // verify required parameter 'xAPIKEY' is not null or undefined
            if (xAPIKEY === null || xAPIKEY === undefined) {
                throw new RequiredError('xAPIKEY','Required parameter xAPIKEY was null or undefined when calling getKeyValuesKey.');
            }
            const localVarPath = `/keyValues/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * APIキー登録
         * @summary APIキー登録
         * @param {ApiKeysPostRequest} [apiKeysPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiKeys: async (apiKeysPostRequest?: ApiKeysPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof apiKeysPostRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(apiKeysPostRequest !== undefined ? apiKeysPostRequest : {})
                : (apiKeysPostRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 退会する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLeave: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact: async (contactPostRequest?: ContactPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactPostRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactPostRequest !== undefined ? contactPostRequest : {})
                : (contactPostRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * キーバリュー登録
         * @summary キーバリュー登録
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPostRequest} [keyValuesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postKeyValues: async (xAPIKEY: string, keyValuesPostRequest?: KeyValuesPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAPIKEY' is not null or undefined
            if (xAPIKEY === null || xAPIKEY === undefined) {
                throw new RequiredError('xAPIKEY','Required parameter xAPIKEY was null or undefined when calling postKeyValues.');
            }
            const localVarPath = `/keyValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof keyValuesPostRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(keyValuesPostRequest !== undefined ? keyValuesPostRequest : {})
                : (keyValuesPostRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * APIキー更新
         * @summary APIキー更新
         * @param {string} apiKey APIキー
         * @param {ApiKeysPutRequest} [apiKeysPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiKeysApiKey: async (apiKey: string, apiKeysPutRequest?: ApiKeysPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            if (apiKey === null || apiKey === undefined) {
                throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling putApiKeysApiKey.');
            }
            const localVarPath = `/apiKeys/{apiKey}`
                .replace(`{${"apiKey"}}`, encodeURIComponent(String(apiKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof apiKeysPutRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(apiKeysPutRequest !== undefined ? apiKeysPutRequest : {})
                : (apiKeysPutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * キーバリュー更新
         * @summary キーバリュー更新
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPutRequest} [keyValuesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putKeyValuesKey: async (key: string, xAPIKEY: string, keyValuesPutRequest?: KeyValuesPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling putKeyValuesKey.');
            }
            // verify required parameter 'xAPIKEY' is not null or undefined
            if (xAPIKEY === null || xAPIKEY === undefined) {
                throw new RequiredError('xAPIKEY','Required parameter xAPIKEY was null or undefined when calling putKeyValuesKey.');
            }
            const localVarPath = `/keyValues/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof keyValuesPutRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(keyValuesPutRequest !== undefined ? keyValuesPutRequest : {})
                : (keyValuesPutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    return {
        /**
         * APIキー削除
         * @summary APIキー削除
         * @param {string} apiKey APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiKeysApiKey(apiKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).deleteApiKeysApiKey(apiKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * キーバリュー削除
         * @summary キーバリュー削除
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKeyValuesKey(key: string, xAPIKEY: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).deleteKeyValuesKey(key, xAPIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * APIキー一覧取得
         * @summary APIキー一覧
         * @param {string} [userId] ユーザーID（管理者のみ指定可能）
         * @param {number} [page] ページ番号
         * @param {number} [num] 1ページのデータ数
         * @param {string} [orders] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiKeys(userId?: string, page?: number, num?: number, orders?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKeysGetResponse>>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).getApiKeys(userId, page, num, orders, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * APIキー詳細取得
         * @summary APIキー詳細
         * @param {string} apiKey APIキー
         * @param {string} [userId] ユーザーID（管理者のみ指定）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiKeysApiKey(apiKey: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeysGetResponse>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).getApiKeysApiKey(apiKey, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ログアウトする
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthLogout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).getAuthLogout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * キーバリュー一覧取得
         * @summary キーバリュー一覧
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeyValues(xAPIKEY: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KeyValuesGetResponse>>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).getKeyValues(xAPIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * キーバリュー詳細取得
         * @summary キーバリュー詳細
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeyValuesKey(key: string, xAPIKEY: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeyValuesGetResponse>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).getKeyValuesKey(key, xAPIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * APIキー登録
         * @summary APIキー登録
         * @param {ApiKeysPostRequest} [apiKeysPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiKeys(apiKeysPostRequest?: ApiKeysPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).postApiKeys(apiKeysPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 退会する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthLeave(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).postAuthLeave(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContact(contactPostRequest?: ContactPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).postContact(contactPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * キーバリュー登録
         * @summary キーバリュー登録
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPostRequest} [keyValuesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postKeyValues(xAPIKEY: string, keyValuesPostRequest?: KeyValuesPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).postKeyValues(xAPIKEY, keyValuesPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * APIキー更新
         * @summary APIキー更新
         * @param {string} apiKey APIキー
         * @param {ApiKeysPutRequest} [apiKeysPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiKeysApiKey(apiKey: string, apiKeysPutRequest?: ApiKeysPutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).putApiKeysApiKey(apiKey, apiKeysPutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * キーバリュー更新
         * @summary キーバリュー更新
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPutRequest} [keyValuesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putKeyValuesKey(key: string, xAPIKEY: string, keyValuesPutRequest?: KeyValuesPutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberApiAxiosParamCreator(configuration).putKeyValuesKey(key, xAPIKEY, keyValuesPutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * APIキー削除
         * @summary APIキー削除
         * @param {string} apiKey APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeysApiKey(apiKey: string, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).deleteApiKeysApiKey(apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * キーバリュー削除
         * @summary キーバリュー削除
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKeyValuesKey(key: string, xAPIKEY: string, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).deleteKeyValuesKey(key, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * APIキー一覧取得
         * @summary APIキー一覧
         * @param {string} [userId] ユーザーID（管理者のみ指定可能）
         * @param {number} [page] ページ番号
         * @param {number} [num] 1ページのデータ数
         * @param {string} [orders] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeys(userId?: string, page?: number, num?: number, orders?: string, options?: any): AxiosPromise<Array<ApiKeysGetResponse>> {
            return MemberApiFp(configuration).getApiKeys(userId, page, num, orders, options).then((request) => request(axios, basePath));
        },
        /**
         * APIキー詳細取得
         * @summary APIキー詳細
         * @param {string} apiKey APIキー
         * @param {string} [userId] ユーザーID（管理者のみ指定）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeysApiKey(apiKey: string, userId?: string, options?: any): AxiosPromise<ApiKeysGetResponse> {
            return MemberApiFp(configuration).getApiKeysApiKey(apiKey, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ログアウトする
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthLogout(options?: any): AxiosPromise<any> {
            return MemberApiFp(configuration).getAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * キーバリュー一覧取得
         * @summary キーバリュー一覧
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyValues(xAPIKEY: string, options?: any): AxiosPromise<Array<KeyValuesGetResponse>> {
            return MemberApiFp(configuration).getKeyValues(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * キーバリュー詳細取得
         * @summary キーバリュー詳細
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyValuesKey(key: string, xAPIKEY: string, options?: any): AxiosPromise<KeyValuesGetResponse> {
            return MemberApiFp(configuration).getKeyValuesKey(key, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * APIキー登録
         * @summary APIキー登録
         * @param {ApiKeysPostRequest} [apiKeysPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiKeys(apiKeysPostRequest?: ApiKeysPostRequest, options?: any): AxiosPromise<string> {
            return MemberApiFp(configuration).postApiKeys(apiKeysPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 退会する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLeave(options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).postAuthLeave(options).then((request) => request(axios, basePath));
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact(contactPostRequest?: ContactPostRequest, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).postContact(contactPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * キーバリュー登録
         * @summary キーバリュー登録
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPostRequest} [keyValuesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postKeyValues(xAPIKEY: string, keyValuesPostRequest?: KeyValuesPostRequest, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).postKeyValues(xAPIKEY, keyValuesPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * APIキー更新
         * @summary APIキー更新
         * @param {string} apiKey APIキー
         * @param {ApiKeysPutRequest} [apiKeysPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiKeysApiKey(apiKey: string, apiKeysPutRequest?: ApiKeysPutRequest, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).putApiKeysApiKey(apiKey, apiKeysPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * キーバリュー更新
         * @summary キーバリュー更新
         * @param {string} key キー
         * @param {string} xAPIKEY APIキー
         * @param {KeyValuesPutRequest} [keyValuesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putKeyValuesKey(key: string, xAPIKEY: string, keyValuesPutRequest?: KeyValuesPutRequest, options?: any): AxiosPromise<void> {
            return MemberApiFp(configuration).putKeyValuesKey(key, xAPIKEY, keyValuesPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * APIキー削除
     * @summary APIキー削除
     * @param {string} apiKey APIキー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public deleteApiKeysApiKey(apiKey: string, options?: any) {
        return MemberApiFp(this.configuration).deleteApiKeysApiKey(apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キーバリュー削除
     * @summary キーバリュー削除
     * @param {string} key キー
     * @param {string} xAPIKEY APIキー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public deleteKeyValuesKey(key: string, xAPIKEY: string, options?: any) {
        return MemberApiFp(this.configuration).deleteKeyValuesKey(key, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIキー一覧取得
     * @summary APIキー一覧
     * @param {string} [userId] ユーザーID（管理者のみ指定可能）
     * @param {number} [page] ページ番号
     * @param {number} [num] 1ページのデータ数
     * @param {string} [orders] 並び順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getApiKeys(userId?: string, page?: number, num?: number, orders?: string, options?: any) {
        return MemberApiFp(this.configuration).getApiKeys(userId, page, num, orders, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIキー詳細取得
     * @summary APIキー詳細
     * @param {string} apiKey APIキー
     * @param {string} [userId] ユーザーID（管理者のみ指定）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getApiKeysApiKey(apiKey: string, userId?: string, options?: any) {
        return MemberApiFp(this.configuration).getApiKeysApiKey(apiKey, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログアウトする
     * @summary logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getAuthLogout(options?: any) {
        return MemberApiFp(this.configuration).getAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キーバリュー一覧取得
     * @summary キーバリュー一覧
     * @param {string} xAPIKEY APIキー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getKeyValues(xAPIKEY: string, options?: any) {
        return MemberApiFp(this.configuration).getKeyValues(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キーバリュー詳細取得
     * @summary キーバリュー詳細
     * @param {string} key キー
     * @param {string} xAPIKEY APIキー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getKeyValuesKey(key: string, xAPIKEY: string, options?: any) {
        return MemberApiFp(this.configuration).getKeyValuesKey(key, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIキー登録
     * @summary APIキー登録
     * @param {ApiKeysPostRequest} [apiKeysPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public postApiKeys(apiKeysPostRequest?: ApiKeysPostRequest, options?: any) {
        return MemberApiFp(this.configuration).postApiKeys(apiKeysPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 退会する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public postAuthLeave(options?: any) {
        return MemberApiFp(this.configuration).postAuthLeave(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問い合わせ
     * @summary 問い合わせ
     * @param {ContactPostRequest} [contactPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public postContact(contactPostRequest?: ContactPostRequest, options?: any) {
        return MemberApiFp(this.configuration).postContact(contactPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キーバリュー登録
     * @summary キーバリュー登録
     * @param {string} xAPIKEY APIキー
     * @param {KeyValuesPostRequest} [keyValuesPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public postKeyValues(xAPIKEY: string, keyValuesPostRequest?: KeyValuesPostRequest, options?: any) {
        return MemberApiFp(this.configuration).postKeyValues(xAPIKEY, keyValuesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIキー更新
     * @summary APIキー更新
     * @param {string} apiKey APIキー
     * @param {ApiKeysPutRequest} [apiKeysPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public putApiKeysApiKey(apiKey: string, apiKeysPutRequest?: ApiKeysPutRequest, options?: any) {
        return MemberApiFp(this.configuration).putApiKeysApiKey(apiKey, apiKeysPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キーバリュー更新
     * @summary キーバリュー更新
     * @param {string} key キー
     * @param {string} xAPIKEY APIキー
     * @param {KeyValuesPutRequest} [keyValuesPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public putKeyValuesKey(key: string, xAPIKEY: string, keyValuesPutRequest?: KeyValuesPutRequest, options?: any) {
        return MemberApiFp(this.configuration).putKeyValuesKey(key, xAPIKEY, keyValuesPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
