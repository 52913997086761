<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item to="/users" v-if="hasReadPermittion('UsersControl')">
          <v-list-item-action>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ユーザー管理</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/keyValues" v-if="hasReadPermittion('KeyValuesService')">
          <v-list-item-action>
            <v-icon>mdi-database</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>キーバリュー管理</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>sapislab管理画面</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="mypage">
            <v-list-item-action>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>マイページ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onClickLogout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>ログアウト</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <slot/>
    </v-main>

    <v-footer app>
      <span>&copy; miya15 2021</span>
    </v-footer>

  </v-app>
</template>

<script>
import { AuthModule } from '@/store/module/auth';
import { logger } from '../logger';

export default {
  name: 'BaseFrame',
  data: () => ({
    drawer: null,
    errorMessage: '',
  }),
  computed: {
    isLogined () {
      return AuthModule.isLogined;
    },
  },
  watch: {
    isLogined(newValue, oldValue) {
      logger.debug(`watch isLogind at BaseFrame ${oldValue} => ${newValue}`);
      this.$router.push('/login');
    },
  },
  methods: {
    onClickLogout() {
      logger.debug('### onClickLogout');
      this.$store.dispatch("auth/logout").finally(() => {
        this.$router.go({ path: "/login", force: true });
      });
    },
    hasReadPermittion(key) {
      if (key in AuthModule.permissionList) {
        if (AuthModule.permissionList[key].indexOf('read') >= 0) {
          logger.trace("AuthModule.permissionList[key].indexOf('read') >= 0 is true");
        }
      }
      else {
        logger.error('permissionList is not exists.');
        return false;
      }
      return (key in AuthModule.permissionList) && (AuthModule.permissionList[key].indexOf('read') >= 0);
    },
  }
};
</script>
