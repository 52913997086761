/* tslint:disable */
/* eslint-disable */
/**
 * management portal
 * management portal
 *
 * The version of the OpenAPI document: 1.0
 * Contact: ks7u4rw2@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RolesGetResponse } from '../model';
// @ts-ignore
import { UserGetResponse } from '../model';
// @ts-ignore
import { UserPostRequest } from '../model';
// @ts-ignore
import { UserPutRequest } from '../model';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザー削除
         * @summary ユーザー削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUsersUserId.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ロール一覧取得
         * @summary ロール一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー一覧取得
         * @summary ユーザー一覧
         * @param {number} [offset] 
         * @param {number} [num] 
         * @param {string} [orders] 並び順
         * @param {string} [searchUserid] ユーザーID
         * @param {string} [searchEmail] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (offset?: number, num?: number, orders?: string, searchUserid?: string, searchEmail?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (orders !== undefined) {
                localVarQueryParameter['orders'] = orders;
            }

            if (searchUserid !== undefined) {
                localVarQueryParameter['searchUserid'] = searchUserid;
            }

            if (searchEmail !== undefined) {
                localVarQueryParameter['searchEmail'] = searchEmail;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー詳細取得
         * @summary ユーザー詳細
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUsersUserId.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー登録
         * @summary ユーザー登録
         * @param {UserPostRequest} [userPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers: async (userPostRequest?: UserPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userPostRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userPostRequest !== undefined ? userPostRequest : {})
                : (userPostRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー更新
         * @summary ユーザー更新
         * @param {string} userId ユーザーID
         * @param {UserPutRequest} [userPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserId: async (userId: string, userPutRequest?: UserPutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putUsersUserId.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userPutRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userPutRequest !== undefined ? userPutRequest : {})
                : (userPutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ユーザー削除
         * @summary ユーザー削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsersUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).deleteUsersUserId(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ロール一覧取得
         * @summary ロール一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesGetResponse>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).getRoles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザー一覧取得
         * @summary ユーザー一覧
         * @param {number} [offset] 
         * @param {number} [num] 
         * @param {string} [orders] 並び順
         * @param {string} [searchUserid] ユーザーID
         * @param {string} [searchEmail] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(offset?: number, num?: number, orders?: string, searchUserid?: string, searchEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGetResponse>>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).getUsers(offset, num, orders, searchUserid, searchEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザー詳細取得
         * @summary ユーザー詳細
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetResponse>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).getUsersUserId(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザー登録
         * @summary ユーザー登録
         * @param {UserPostRequest} [userPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsers(userPostRequest?: UserPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).postUsers(userPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザー更新
         * @summary ユーザー更新
         * @param {string} userId ユーザーID
         * @param {UserPutRequest} [userPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUsersUserId(userId: string, userPutRequest?: UserPutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).putUsersUserId(userId, userPutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ユーザー削除
         * @summary ユーザー削除
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUserId(userId: string, options?: any): AxiosPromise<void> {
            return AdminApiFp(configuration).deleteUsersUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ロール一覧取得
         * @summary ロール一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<RolesGetResponse>> {
            return AdminApiFp(configuration).getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー一覧取得
         * @summary ユーザー一覧
         * @param {number} [offset] 
         * @param {number} [num] 
         * @param {string} [orders] 並び順
         * @param {string} [searchUserid] ユーザーID
         * @param {string} [searchEmail] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(offset?: number, num?: number, orders?: string, searchUserid?: string, searchEmail?: string, options?: any): AxiosPromise<Array<UserGetResponse>> {
            return AdminApiFp(configuration).getUsers(offset, num, orders, searchUserid, searchEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー詳細取得
         * @summary ユーザー詳細
         * @param {string} userId ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserId(userId: string, options?: any): AxiosPromise<UserGetResponse> {
            return AdminApiFp(configuration).getUsersUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー登録
         * @summary ユーザー登録
         * @param {UserPostRequest} [userPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(userPostRequest?: UserPostRequest, options?: any): AxiosPromise<void> {
            return AdminApiFp(configuration).postUsers(userPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー更新
         * @summary ユーザー更新
         * @param {string} userId ユーザーID
         * @param {UserPutRequest} [userPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserId(userId: string, userPutRequest?: UserPutRequest, options?: any): AxiosPromise<void> {
            return AdminApiFp(configuration).putUsersUserId(userId, userPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * ユーザー削除
     * @summary ユーザー削除
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteUsersUserId(userId: string, options?: any) {
        return AdminApiFp(this.configuration).deleteUsersUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ロール一覧取得
     * @summary ロール一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getRoles(options?: any) {
        return AdminApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー一覧取得
     * @summary ユーザー一覧
     * @param {number} [offset] 
     * @param {number} [num] 
     * @param {string} [orders] 並び順
     * @param {string} [searchUserid] ユーザーID
     * @param {string} [searchEmail] メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUsers(offset?: number, num?: number, orders?: string, searchUserid?: string, searchEmail?: string, options?: any) {
        return AdminApiFp(this.configuration).getUsers(offset, num, orders, searchUserid, searchEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー詳細取得
     * @summary ユーザー詳細
     * @param {string} userId ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getUsersUserId(userId: string, options?: any) {
        return AdminApiFp(this.configuration).getUsersUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー登録
     * @summary ユーザー登録
     * @param {UserPostRequest} [userPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postUsers(userPostRequest?: UserPostRequest, options?: any) {
        return AdminApiFp(this.configuration).postUsers(userPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー更新
     * @summary ユーザー更新
     * @param {string} userId ユーザーID
     * @param {UserPutRequest} [userPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putUsersUserId(userId: string, userPutRequest?: UserPutRequest, options?: any) {
        return AdminApiFp(this.configuration).putUsersUserId(userId, userPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
