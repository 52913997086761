var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseFrame',[_c('ApiKeys',{on:{"change-selected":_vm.onChangeApiKey}}),(_vm.apiKey !== '')?_c('div',[_c('v-card',{staticClass:"blue-grey darken-4"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"64px"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"title"},[_vm._v("キーバリュー")]),_c('v-spacer'),_c('v-col',{staticClass:"py-0 pl-0 pr-4",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ms-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onNewClick}},[_vm._v("新規作成")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v("mdi-file")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,1049167897)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"80%","overlay-opacity":"0.85"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('v-card',{staticClass:"blue-grey darken-4"},[_c('v-card-title',{staticClass:"blue-grey darken-3"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"APIキー","readonly":""},model:{value:(_vm.editedItem.apiKey),callback:function ($$v) {_vm.$set(_vm.editedItem, "apiKey", $$v)},expression:"editedItem.apiKey"}})],1),(_vm.dialogState === _vm.DLG_STATE.NEW)?_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-row',[_c('v-text-field',{attrs:{"label":"Key","error-messages":errors,"success":valid,"readonly":!_vm.saveButton},model:{value:(_vm.editedItem.key),callback:function ($$v) {_vm.$set(_vm.editedItem, "key", $$v)},expression:"editedItem.key"}})],1)]}}],null,true)}):_c('v-row',[_c('v-text-field',{attrs:{"label":"Key","readonly":""},model:{value:(_vm.editedItem.key),callback:function ($$v) {_vm.$set(_vm.editedItem, "key", $$v)},expression:"editedItem.key"}})],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-row',[_c('v-text-field',{attrs:{"label":"Value","error-messages":errors,"success":valid,"readonly":!_vm.saveButton},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}})],1)]}}],null,true)}),_c('v-row',[_c('v-text-field',{attrs:{"label":"UpdatedAt","readonly":""},model:{value:(_vm.editedItem.updatedAtStr),callback:function ($$v) {_vm.$set(_vm.editedItem, "updatedAtStr", $$v)},expression:"editedItem.updatedAtStr"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"RegisteredAt","readonly":""},model:{value:(_vm.editedItem.registeredAtStr),callback:function ($$v) {_vm.$set(_vm.editedItem, "registeredAtStr", $$v)},expression:"editedItem.registeredAtStr"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),(_vm.saveButton)?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Save")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }