<template>
  <v-app app>
    <router-view/>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" id="ctrlSnackbar">
      {{ snackbar.text }}
      <v-btn color="blue" text @click="snackbar.show = false" id="btnSnackbarClose">
        Close
      </v-btn>
    </v-snackbar>
    <v-overlay :value="fullOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { logger } from './logger';
import { AuthModule } from '@/store/module/auth';
// import { validate } from 'vee-validate';
// import { required, email, max, min } from "vee-validate/dist/rules";

export default {
  name: 'App',

  data: () => ({
    snackbar: {
      show: false,
      text: '',
      timeout: 3000,
    }
  }),
  computed: {
    fullOverlay() {
      logger.trace('computed AuthModule.fullOverlay', AuthModule.fullOverlay);
      return AuthModule.fullOverlay;
    },
    snackbarInfo() {
      return AuthModule.snackbarInfo;
    },
  },
  beforeCreate() {
    this.$store.dispatch('auth/checkLogined')
    .catch(() => { logger.trace("App beforeCreate catch"); });

    // logger.trace('### start validate');
    // const r = validate('som', 'required|max:3').then(result => {
    //   logger.trace('### validate');
    //   logger.trace(result);
    //   return true;
    // });
    // logger.trace('### end validate ' + r);
    // const r2 = email.validate('aaa@bbb.ccc');
    // logger.trace('### validate direct email is ' + r2);
    // const r3 = email.validate('aaa@bbb.c');
    // logger.trace('### validate direct email is ' + r3);
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  watch: {
    snackbarInfo(newValue) {
      logger.debug('### watch snackbarInfo');
      logger.debug(newValue);
      if ('text' in newValue && 'timeout' in newValue) {
        logger.debug('set snackbar');
        this.snackbar.show = true;
        this.snackbar.text = newValue.text;
        this.snackbar.timeout = newValue.timeout;
      }
    }
  }
};
</script>
