import { logger } from "@/logger";
import store from ".";

let localCounter = 0;

/**
 * オーバーレイ表示用デコレーター
 * @return {MethodDecorator}
 */
export function OverlayDecorator(): MethodDecorator {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (target: Object, methodName: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const originalMethodFunc = async () => {
        return originalMethod.apply(this, [...args]);
      };
      const asyncFunc = async () => {
        localCounter++;
        logger.trace("●●● OverlayDecorator count up", localCounter);
        store.dispatch("auth/setFullOverlay", true, { root: true });
        let promise;
        try {
          promise = await originalMethodFunc();
        } finally {
          localCounter--;
          logger.trace("●●● OverlayDecorator count down", localCounter);
          if (localCounter <= 0) {
            store.dispatch("auth/setFullOverlay", false, { root: true });
          }
        }
        return promise;
      };
      return asyncFunc();
    };
  };
}
