import { Vue, Component, Watch } from "vue-property-decorator";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { AuthModule } from "@/store/module/auth";
import { logger } from "@/logger";
import { SignupInfo } from "generated/openapi-generator";
import TermsOfService from "@/components/termsOfService/TermsOfService.vue";
import PublicFrame from "@/components/PublicFrame.vue";

// パスワード確認用
extend("password", {
  params: ["target"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate: (value, params: Record<string, any>) => {
    return value === params.target;
  },
  message: "パスワードの確認が一致していません",
});

@Component({ components: { TermsOfService, ValidationObserver, ValidationProvider, PublicFrame } })
/**
 * サインアップ画面
 */
export default class Signup extends Vue {
  userId = "";
  email = "";
  password = "";
  confirmPassword = "";
  errorMessage = "";
  errorValidation = "";
  agreeTermsOfService = false;

  /**
   * ログインしているかどうかのアクセサ
   */
  get isLogined(): boolean {
    return AuthModule.isLogined;
  }

  /**
   * サインアップボタンクリック時の処理
   */
  async onClickSignup(): Promise<void> {
    logger.debug("onClickSignup start");
    await this.$recaptchaLoaded();
    const recaptchaToken = await this.$recaptcha("signup");
    const signupInfo: SignupInfo = {
      userId: this.userId,
      email: this.email,
      password: this.password,
      recaptchaToken,
    };
    this.$store.dispatch("auth/signup", signupInfo).then(() => {
      logger.trace("Signup succeeded.");
      this.$router.push("/authenticationguidance");
    });
  }

  /**
   * errorValidationの監視
   * @param {string} newValue
   */
  @Watch("errorValidation")
  onChangeErrorValidation(newValue: string): void {
    if (typeof newValue === "string" && newValue.length > 0) {
      logger.debug("### errorValidation");
      logger.debug(newValue);
      logger.debug(JSON.parse(newValue));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obs: any = this.$refs.obs;
      if (obs && "setErrors" in obs) {
        obs.setErrors(JSON.parse(newValue));
      }
    }
  }

  /**
   * 利用規約ダイアログから同意が押された時の処理
   * @param {boolean} flag
   */
  onChangeAgree(flag: boolean): void {
    this.agreeTermsOfService = flag;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$nextTick(() => {
      const obs: any = this.$refs.obs;
      obs.validate();
    });
  }
}
