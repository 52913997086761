import { Vue, Component, Prop } from "vue-property-decorator";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { PasswordRemind, ChangePassword } from "generated/openapi-generator";
import CommonUtil from "@/utils/commonUtil";

// パスワード確認用
extend("password", {
  params: ["target"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate: (value, params: Record<string, any>) => {
    return value === params.target;
  },
  message: "パスワードの確認が一致していません",
});

@Component({ components: { ValidationObserver, ValidationProvider } })
/**
 * パスワード変更フォーム
 */
export default class PasswordChangeForm extends Vue {
  userId = "";
  email = "";
  passwordBefore = "";
  passwordAfter = "";
  confirmPassword = "";

  @Prop({ type: Boolean, default: false })
  showDialog: boolean | undefined;

  @Prop({ type: String, default: "" })
  showType: string | undefined;

  /**
   * キャンセルボタンクリック時の処理
   */
  onClickCancel(): void {
    this.$emit("clickClose");
  }

  /**
   * 送信ボタンクリック時の処理
   */
  async onClickSend(): Promise<void> {
    if (this.showType === "request") {
      const request: PasswordRemind = {
        userId: this.userId,
        email: this.email,
      };
      this.$store.dispatch("auth/passwordRemind", request).then(() => {
        CommonUtil.infoMessage(this.$store, "パスワードリマインドのリクエストを送信しました");
      });
      this.$emit("clickClose");
    } else if (this.showType === "changeWithToken") {
      const request: ChangePassword = {
        passwordRemindToken: this.$route.query.token as string,
        after: this.passwordAfter,
      };
      this.$store.dispatch("auth/passwordChange", request).then(() => {
        CommonUtil.infoMessage(this.$store, "パスワードを変更しました");
      });
      this.$emit("clickClose");
    } else if (this.showType === "changeFromMypage") {
      const request: ChangePassword = {
        before: this.passwordBefore,
        after: this.passwordAfter,
      };
      this.$store.dispatch("auth/passwordChange", request).then(() => {
        CommonUtil.infoMessage(this.$store, "パスワードを変更しました");
      });
      this.$emit("clickClose");
    }
  }
}
