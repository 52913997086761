import { Configuration, ContactPostRequest, PublicApi } from "../../../generated/openapi-generator";
import { Module, VuexModule, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import CommonUtil from "@/utils/commonUtil";
import { OverlayDecorator } from "../utils";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IEtcState {
  dummy?: void;
}

/**
 * その他のストアモジュール
 */
@Module({ dynamic: true, store, name: "etc", namespaced: true })
class Etc extends VuexModule implements IEtcState {
  dummy?: void = undefined;

  /**
   * contactアクション
   * @param {ContactPostRequest} request
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  postContact(request: ContactPostRequest) {
    logger.trace("Etc action postContact");

    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .postContact(request)
        .then((response) => {
          logger.trace("Etc action postContact then", response);
          resolve();
        })
        .catch((reason) => {
          logger.trace("Etc action postContact catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }
}

export const EtcModule = getModule(Etc);
