var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PublicFrame',[_c('v-layout',{attrs:{"align-center":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"blue"}},[_c('v-toolbar-title',[_vm._v("新規登録")])],1),_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"userId","label":"ユーザーID","id":"userId","type":"text","error-messages":errors,"success":valid},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"email","label":"メールアドレス","id":"email","type":"text","error-messages":errors,"success":valid},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"password","label":"パスワード","id":"password","type":"password","error-messages":errors,"success":valid},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8|max:32|password:@password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"name":"confirmPassword","label":"パスワード確認","id":"confirmPassword","type":"password","error-messages":errors,"success":valid},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } },"vid":"agreeTermsOfService"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-row',{attrs:{"align":"center"}},[_c('TermsOfService',{on:{"onChangeAgree":_vm.onChangeAgree}}),_c('v-checkbox',{attrs:{"label":"同意済み","error-messages":errors,"success":valid,"readonly":""},model:{value:(_vm.agreeTermsOfService),callback:function ($$v) {_vm.agreeTermsOfService=$$v},expression:"agreeTermsOfService"}})],1)]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"blue","disabled":invalid || !validated},on:{"click":_vm.onClickSignup}},[_vm._v("登録")])],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"type":"warning","dense":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-card-text',{staticClass:"pa-0 ma-0 text-right"},[_vm._v("登録と制限内のご利用は無料です")])],1)]}}])}),_c('br'),_c('div',{staticClass:"caption"},[_vm._v(" アカウントをお持ちの場合は"),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("ログイン")]),_vm._v("をお願いします。 ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }