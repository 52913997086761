import { Configuration, AdminApi, UserGetResponse, UserSearchCondition } from "../../../generated/openapi-generator";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import { UserItem } from "@/model/user";
import moment from "moment";
import CommonUtil from "@/utils/commonUtil";
import { OverlayDecorator } from "../utils";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IUsersState {
  items: Array<UserItem>;
  specificItem: UserItem;
}

/**
 * ユーザー管理用のストアモジュール
 */
@Module({ dynamic: true, store, name: "users", namespaced: true })
class Users extends VuexModule implements IUsersState {
  private _users: Array<UserGetResponse> = [];
  private _userDetail: UserGetResponse = { userId: "", email: "", isAuthenticated: false, roles: [], loginFailedCount: 0, isAdmin: false };

  /**
   * items
   */
  public get items(): Array<UserItem> {
    const result = this._users.reduce((prev, cur) => {
      prev.push({
        userId: cur.userId!,
        email: cur.email!,
        isAuthenticated: cur.isAuthenticated!,
        loginedAtStr: moment.unix(cur.loginedAt ? cur.loginedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        registeredAtStr: moment.unix(cur.registeredAt ? cur.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        updatedAtStr: moment.unix(cur.updatedAt ? cur.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        updatedAt: cur.updatedAt ? cur.updatedAt : 0,
        roles: cur.roles,
        loginFailedCount: cur.loginFailedCount!,
        isAdmin: cur.isAdmin,
      });
      return prev;
    }, new Array<UserItem>());
    return result;
  }

  /**
   * specificItem
   */
  public get specificItem(): UserItem {
    return {
      userId: this._userDetail.userId!,
      email: this._userDetail.email!,
      isAuthenticated: this._userDetail.isAuthenticated!,
      loginedAtStr: moment.unix(this._userDetail.loginedAt ? this._userDetail.loginedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      registeredAtStr: moment.unix(this._userDetail.registeredAt ? this._userDetail.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      updatedAtStr: moment.unix(this._userDetail.updatedAt ? this._userDetail.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      updatedAt: this._userDetail.updatedAt ? this._userDetail.updatedAt : 0,
      roles: this._userDetail.roles,
      loginFailedCount: this._userDetail.loginFailedCount!,
      isAdmin: this._userDetail.isAdmin,
    };
  }

  /**
   * getListアクション
   * @param {UserSearchCondition} searchCondition
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getList(searchCondition: UserSearchCondition) {
    const offset = searchCondition.offset;
    const num = searchCondition.num;
    const orders = undefined;
    const searchUserId = searchCondition.userId;
    const searchEmail = searchCondition.email;
    logger.trace("Users action getList", searchUserId);

    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .getUsers(offset, num, orders, searchUserId, searchEmail)
        .then((response) => {
          logger.trace("Users action getList then");
          this.context.commit("updateList", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("Users action getList catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * getUserアクション
   * @param {string} userId
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getUser(userId: string) {
    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .getUsersUserId(userId)
        .then((response) => {
          logger.trace("Users action getUser then");
          this.context.commit("updateUserDetail", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("Users action getUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * ユーザー作成
   * @param {UserItem} userItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  createUser(userItem: UserItem) {
    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .postUsers({
          userId: userItem.userId,
          email: userItem.email,
          password: userItem.password!,
          isAuthenticated: userItem.isAuthenticated,
          roles: userItem.roles,
          loginFailedCount: userItem.loginFailedCount,
          isAdmin: userItem.isAdmin,
        })
        .then(() => {
          logger.trace("Users action createUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("Users action createUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * ユーザー更新
   * @param {UserItem} userItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  updateUser(userItem: UserItem) {
    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .putUsersUserId(userItem.userId, {
          email: userItem.email,
          isAuthenticated: userItem.isAuthenticated,
          updatedAt: userItem.updatedAt,
          roles: userItem.roles,
          loginFailedCount: userItem.loginFailedCount,
          isAdmin: userItem.isAdmin,
        })
        .then(() => {
          logger.trace("Users action updateUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("Users action updateUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * ユーザー削除
   * @param {string} userId
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  deleteUser(userId: string) {
    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .deleteUsersUserId(userId)
        .then(() => {
          logger.trace("Users action deleteUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("Users action deleteUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * リストを更新する
   * @param {Array<UserGetResponse>} users リスト
   */
  @Mutation
  updateList(users: Array<UserGetResponse>) {
    logger.trace("Users mutation updateList", users);
    this._users = users;
  }

  /**
   * ユーザー詳細を更新する
   * @param {Array<UserGetResponse>} user ユーザー情報
   */
  @Mutation
  updateUserDetail(user: UserGetResponse) {
    logger.trace("Users mutation updateUserDetail", user);
    this._userDetail = user;
  }
}

export const UsersModule = getModule(Users);
