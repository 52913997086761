import { ErrorInfoList } from "generated/openapi-generator";
import { VuexModule } from "vuex-module-decorators";

/**
 * 共通ユーティリティ
 */
export default class CommonUtil {
  /**
   * エラーハンドリング
   * @param {VuexModule} module
   * @param {any} reason
   */
  public static errorHandling(module: VuexModule, reason: any): void {
    if (reason.response) {
      const errorInfoList: ErrorInfoList = reason.response.data;
      if (errorInfoList && "errors" in errorInfoList) {
        module.context.dispatch(
          "auth/showSnackbar",
          {
            text: errorInfoList.errors[0].message,
            timeout: 5000,
          },
          { root: true }
        );
      }
    }
  }

  /**
   * メッセージ
   * @param {any} store
   * @param {string} message
   */
  public static infoMessage(store: any, message: string): void {
    store.dispatch(
      "auth/showSnackbar",
      {
        text: message,
        timeout: 5000,
      },
      { root: true }
    );
  }
}
