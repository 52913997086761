/* tslint:disable */
/* eslint-disable */
/**
 * management portal
 * management portal
 *
 * The version of the OpenAPI document: 1.0
 * Contact: ks7u4rw2@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthResponse } from '../model';
// @ts-ignore
import { ChangePassword } from '../model';
// @ts-ignore
import { ContactPostRequest } from '../model';
// @ts-ignore
import { ErrorInfoList } from '../model';
// @ts-ignore
import { InlineResponse200 } from '../model';
// @ts-ignore
import { LoginInfo } from '../model';
// @ts-ignore
import { MailAuthenticateInfo } from '../model';
// @ts-ignore
import { PasswordRemind } from '../model';
// @ts-ignore
import { SignupInfo } from '../model';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログイン済みかどうか
         * @summary isLogined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthIsLogined: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/isLogined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * エコー
         * @summary エコー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcho: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインする
         * @summary login
         * @param {LoginInfo} [loginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin: async (loginInfo?: LoginInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginInfo !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginInfo !== undefined ? loginInfo : {})
                : (loginInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * mail authenticate
         * @summary メール認証
         * @param {MailAuthenticateInfo} [mailAuthenticateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthMailAuthenticate: async (mailAuthenticateInfo?: MailAuthenticateInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/mailAuthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailAuthenticateInfo !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailAuthenticateInfo !== undefined ? mailAuthenticateInfo : {})
                : (mailAuthenticateInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードリマインド
         * @param {PasswordRemind} [passwordRemind] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPasswordRemind: async (passwordRemind?: PasswordRemind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/passwordRemind`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passwordRemind !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passwordRemind !== undefined ? passwordRemind : {})
                : (passwordRemind || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * signup
         * @summary signup
         * @param {SignupInfo} [signupInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthSignup: async (signupInfo?: SignupInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof signupInfo !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(signupInfo !== undefined ? signupInfo : {})
                : (signupInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact: async (contactPostRequest?: ContactPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contactPostRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contactPostRequest !== undefined ? contactPostRequest : {})
                : (contactPostRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードを変更する
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAuthPassword: async (changePassword?: ChangePassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changePassword !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changePassword !== undefined ? changePassword : {})
                : (changePassword || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ログイン済みかどうか
         * @summary isLogined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthIsLogined(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).getAuthIsLogined(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * エコー
         * @summary エコー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEcho(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).getEcho(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ログインする
         * @summary login
         * @param {LoginInfo} [loginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthLogin(loginInfo?: LoginInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).postAuthLogin(loginInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * mail authenticate
         * @summary メール認証
         * @param {MailAuthenticateInfo} [mailAuthenticateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthMailAuthenticate(mailAuthenticateInfo?: MailAuthenticateInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).postAuthMailAuthenticate(mailAuthenticateInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * パスワードリマインド
         * @param {PasswordRemind} [passwordRemind] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthPasswordRemind(passwordRemind?: PasswordRemind, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).postAuthPasswordRemind(passwordRemind, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * signup
         * @summary signup
         * @param {SignupInfo} [signupInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthSignup(signupInfo?: SignupInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).postAuthSignup(signupInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContact(contactPostRequest?: ContactPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).postContact(contactPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * パスワードを変更する
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAuthPassword(changePassword?: ChangePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PublicApiAxiosParamCreator(configuration).putAuthPassword(changePassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ログイン済みかどうか
         * @summary isLogined
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthIsLogined(options?: any): AxiosPromise<AuthResponse> {
            return PublicApiFp(configuration).getAuthIsLogined(options).then((request) => request(axios, basePath));
        },
        /**
         * エコー
         * @summary エコー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcho(options?: any): AxiosPromise<void> {
            return PublicApiFp(configuration).getEcho(options).then((request) => request(axios, basePath));
        },
        /**
         * ログインする
         * @summary login
         * @param {LoginInfo} [loginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin(loginInfo?: LoginInfo, options?: any): AxiosPromise<AuthResponse> {
            return PublicApiFp(configuration).postAuthLogin(loginInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * mail authenticate
         * @summary メール認証
         * @param {MailAuthenticateInfo} [mailAuthenticateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthMailAuthenticate(mailAuthenticateInfo?: MailAuthenticateInfo, options?: any): AxiosPromise<void> {
            return PublicApiFp(configuration).postAuthMailAuthenticate(mailAuthenticateInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードリマインド
         * @param {PasswordRemind} [passwordRemind] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPasswordRemind(passwordRemind?: PasswordRemind, options?: any): AxiosPromise<void> {
            return PublicApiFp(configuration).postAuthPasswordRemind(passwordRemind, options).then((request) => request(axios, basePath));
        },
        /**
         * signup
         * @summary signup
         * @param {SignupInfo} [signupInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthSignup(signupInfo?: SignupInfo, options?: any): AxiosPromise<InlineResponse200> {
            return PublicApiFp(configuration).postAuthSignup(signupInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 問い合わせ
         * @summary 問い合わせ
         * @param {ContactPostRequest} [contactPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact(contactPostRequest?: ContactPostRequest, options?: any): AxiosPromise<void> {
            return PublicApiFp(configuration).postContact(contactPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードを変更する
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAuthPassword(changePassword?: ChangePassword, options?: any): AxiosPromise<void> {
            return PublicApiFp(configuration).putAuthPassword(changePassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * ログイン済みかどうか
     * @summary isLogined
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getAuthIsLogined(options?: any) {
        return PublicApiFp(this.configuration).getAuthIsLogined(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エコー
     * @summary エコー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getEcho(options?: any) {
        return PublicApiFp(this.configuration).getEcho(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインする
     * @summary login
     * @param {LoginInfo} [loginInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public postAuthLogin(loginInfo?: LoginInfo, options?: any) {
        return PublicApiFp(this.configuration).postAuthLogin(loginInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * mail authenticate
     * @summary メール認証
     * @param {MailAuthenticateInfo} [mailAuthenticateInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public postAuthMailAuthenticate(mailAuthenticateInfo?: MailAuthenticateInfo, options?: any) {
        return PublicApiFp(this.configuration).postAuthMailAuthenticate(mailAuthenticateInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードリマインド
     * @param {PasswordRemind} [passwordRemind] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public postAuthPasswordRemind(passwordRemind?: PasswordRemind, options?: any) {
        return PublicApiFp(this.configuration).postAuthPasswordRemind(passwordRemind, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * signup
     * @summary signup
     * @param {SignupInfo} [signupInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public postAuthSignup(signupInfo?: SignupInfo, options?: any) {
        return PublicApiFp(this.configuration).postAuthSignup(signupInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問い合わせ
     * @summary 問い合わせ
     * @param {ContactPostRequest} [contactPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public postContact(contactPostRequest?: ContactPostRequest, options?: any) {
        return PublicApiFp(this.configuration).postContact(contactPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードを変更する
     * @param {ChangePassword} [changePassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public putAuthPassword(changePassword?: ChangePassword, options?: any) {
        return PublicApiFp(this.configuration).putAuthPassword(changePassword, options).then((request) => request(this.axios, this.basePath));
    }
}
