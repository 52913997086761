"use strict";

const levels = ["trace", "debug", "info", "warn", "error", "fatal"];

/**
 * ロガー
 */
class Logger {
  /**
   * 出力すべきかどうかを判定する
   * @param {string} myLevel 自身のレベル
   * @return {boolean}
   */
  private shouldOutput(myLevel: string): boolean {
    const needsLevel = process.env.VUE_APP_LOG_LEVEL ? process.env.VUE_APP_LOG_LEVEL : "error";
    const indexNeeds = levels.indexOf(needsLevel);
    const indexMe = levels.indexOf(myLevel);
    return indexNeeds <= indexMe;
  }

  /**
   * trace
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public trace(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("trace")) {
      console.log(msg, args);
    }
  }

  /**
   * debug
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public debug(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("debug")) {
      console.log(msg, args);
    }
  }

  /**
   * info
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public info(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("info")) {
      console.log(msg, args);
    }
  }

  /**
   * warn
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public warn(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("warn")) {
      console.warn(msg, args);
    }
  }

  /**
   * error
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public error(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("error")) {
      console.error(msg, args);
    }
  }

  /**
   * fatal
   * @param {string} msg メッセージ
   * @param {any[]} args
   */
  public fatal(msg: string, ...args: unknown[]) {
    if (this.shouldOutput("fatal")) {
      console.error(msg, args);
    }
  }
}
// const debugLevel =
//   "VUE_APP_LOG_LEVEL" in process.env ? process.env.VUE_APP_LOG_LEVEL : "error";
export const logger = new Logger();
