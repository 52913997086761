import Vue from "vue";
import Vuex from "vuex";
import { IAuthState } from "./module/auth";
import { IUsersState } from "./module/users";
import { IApiKeysState } from "./module/apiKeys";
import { IKeyValuesState } from "./module/keyValues";
import { IRolesState } from "./module/roles";
import { IEtcState } from "./module/etc";

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
  users: IUsersState;
  apiKeys: IApiKeysState;
  keyValues: IKeyValuesState;
  roles: IRolesState;
  etc: IEtcState;
}

export default new Vuex.Store<IRootState>({});
