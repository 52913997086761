import { Vue, Component, Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PublicFrame from "@/components/PublicFrame.vue";
import PasswordChangeForm from "@/components/passwordChangeForm/PasswordChangeForm.vue";
import ContactForm from "@/components/contactForm/ContactForm.vue";
import { AuthModule } from "@/store/module/auth";
import { logger } from "@/logger";

@Component({ components: { PublicFrame, PasswordChangeForm, ContactForm, ValidationObserver, ValidationProvider } })
/**
 * ログイン画面
 */
export default class Login extends Vue {
  userId = "";
  password = "";
  errorMessage = "";
  errorValidatio = "";
  showPasswordChangeForm = false;
  showContactForm = false;

  /**
   * ログインしているかどうかのアクセサ
   */
  get isLogined(): boolean {
    return AuthModule.isLogined;
  }

  /**
   * ログインボタンクリック時の処理
   */
  onClickLogin(): void {
    this.$store.dispatch("auth/login", { userId: this.userId, password: this.password }).catch((reason) => {
      logger.trace("onClickLogin catch", reason);
    });
  }

  /**
   * ログイン状態の監視
   * @param {boolean} newValue 新しい値
   * @param {boolean} oldValue 前の値
   */
  @Watch("isLogined")
  onChangeIsLogined(newValue: boolean, oldValue: boolean): void {
    logger.debug(`watch isLogind at Login ${oldValue} => ${newValue}`);
    logger.debug("query", this.$route.query);
    let to = "/";
    if ("redirect" in this.$route.query && this.$route.query.redirect !== "/login" && typeof this.$route.query.redirect === "string") {
      to = this.$route.query.redirect;
    }
    this.$router.push(to);
  }

  /**
   * バリデーションエラーの監視
   * @param {string} newValue 新しい値
   */
  @Watch("errorValidation")
  onChangeErrorValidation(newValue: string): void {
    if (typeof newValue === "string" && newValue.length > 0) {
      logger.debug("### errorValidation");
      logger.debug(newValue);
      logger.debug(JSON.parse(newValue));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obs: any = this.$refs.obs;
      if (obs && "setErrors" in obs) {
        obs.setErrors(JSON.parse(newValue));
      }
    }
  }

  /**
   * パスワード変更フォーム表示ボタンクリック時
   */
  onClickShowPasswordChangeForm(): void {
    this.showPasswordChangeForm = true;
  }

  /**
   * お問い合わせフォーム表示ボタンクリック時
   */
  onClickShowContactForm(): void {
    this.showContactForm = true;
  }
}
