import { Vue, Component } from "vue-property-decorator";
import { logger } from "@/logger";
import PublicFrame from "@/components/PublicFrame.vue";

@Component({ components: { PublicFrame } })
/**
 * 登録完了画面
 */
export default class Regist extends Vue {
  authenticateFinished = false;

  /**
   * DOMの準備が終わった後の処理
   */
  mounted(): void {
    logger.trace("Regist mounted");
    this.$store.dispatch("auth/mailAuthenticate", this.$route.query.token).then(() => {
      this.authenticateFinished = true;
    });
  }
}
