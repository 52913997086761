import { ApiKeysPostRequest, ApiKeysSearchCondition, Configuration, ErrorInfoList, MemberApi } from "../../../generated/openapi-generator";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import { ApiKeysItem } from "@/model/apiKeysItem";
import { ApiKeysGetResponse } from "generated/openapi-generator/model/api-keys-get-response";
import { OverlayDecorator } from "../utils";
import CommonUtil from "@/utils/commonUtil";
import moment from "moment";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IApiKeysState {
  items: Array<ApiKeysItem>;
  specificItem: ApiKeysItem;
}

/**
 * APIキー管理用のストアモジュール
 */
@Module({ dynamic: true, store, name: "apiKeys", namespaced: true })
class ApiKeys extends VuexModule implements IApiKeysState {
  private _apiKeys: Array<ApiKeysGetResponse> = [];
  private _apiKeysDetail: ApiKeysGetResponse = {};

  /**
   * items
   */
  public get items(): Array<ApiKeysItem> {
    const result = this._apiKeys.reduce((prev, cur) => {
      prev.push({
        name: cur.name!,
        apiKey: cur.apiKey!,
        secretKey: cur.secretKey!,
        service: cur.service!,
        userId: cur.userId!,
        updatedAt: cur.updatedAt!,
        updatedAtStr: moment.unix(cur.updatedAt ? cur.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        registeredAt: cur.registeredAt!,
        registeredAtStr: moment.unix(cur.registeredAt ? cur.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      });
      return prev;
    }, new Array<ApiKeysItem>());
    return result;
  }

  /**
   * specificItem
   */
  public get specificItem(): ApiKeysItem {
    return {
      name: this._apiKeysDetail.name!,
      apiKey: this._apiKeysDetail.apiKey!,
      secretKey: this._apiKeysDetail.secretKey!,
      service: this._apiKeysDetail.service!,
      userId: this._apiKeysDetail.userId!,
      updatedAt: this._apiKeysDetail.updatedAt!,
      updatedAtStr: moment.unix(this._apiKeysDetail.updatedAt ? this._apiKeysDetail.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      registeredAt: this._apiKeysDetail.registeredAt!,
      registeredAtStr: moment.unix(this._apiKeysDetail.registeredAt ? this._apiKeysDetail.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
    };
  }

  /**
   * getListアクション
   * @param {ApiKeysSearchCondition} searchCondition
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getList(searchCondition: ApiKeysSearchCondition) {
    logger.trace("ApiKeys action getList");
    const num = searchCondition.num;
    const page = searchCondition.offset;
    const orders = undefined;
    const searchUserId = searchCondition.userId;

    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .getApiKeys(searchUserId, page, num, orders)
        .then((response) => {
          logger.trace("ApiKeys action getList then");
          this.context.commit("updateList", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("ApiKeys action getList catch", reason);
          if (reason.response) {
            const errorInfoList: ErrorInfoList = reason.response.data;
            if (errorInfoList) {
              this.context.dispatch("showSnackbar", {
                text: errorInfoList.errors[0].message,
                timeout: 5000,
              });
            }
          }
          reject(reason);
        });
    });
  }

  /**
   * getApiKeyアクション
   * @param {string} apiKeysItem
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getApiKey(apiKeysItem: ApiKeysItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .getApiKeysApiKey(apiKeysItem.apiKey!, apiKeysItem.userId)
        .then((response) => {
          logger.trace("ApiKeys action getApiKey then");
          this.context.commit("updateUserDetail", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("ApiKeys action getApiKey catch", reason);
          if (reason.response) {
            const errorInfoList: ErrorInfoList = reason.response.data;
            if (errorInfoList) {
              this.context.dispatch("showSnackbar", {
                text: errorInfoList.errors[0].message,
                timeout: 5000,
              });
            }
          }
          reject(reason);
        });
    });
  }

  /**
   * createApiKeyアクション
   * @param {ApiKeysPostRequest} apiKeysPostRequest
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  createApiKey(apiKeysPostRequest: ApiKeysPostRequest) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .postApiKeys(apiKeysPostRequest)
        .then(() => {
          logger.trace("ApiKeys action createApiKey then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("ApiKeys action createApiKey catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * updateApiKeyアクション
   * @param {ApiKeysItem} apiKeysItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  updateApiKey(apiKeysItem: ApiKeysItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .putApiKeysApiKey(apiKeysItem.apiKey, {
          name: apiKeysItem.name,
          secretKey: apiKeysItem.secretKey,
          service: apiKeysItem.service,
          userId: apiKeysItem.userId,
          updatedAt: apiKeysItem.updatedAt,
        })
        .then(() => {
          logger.trace("ApiKeys action updateApiKey then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("ApiKeys action updateApiKey catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * deleteApiKeyアクション
   * @param {string} apiKey
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  deleteApiKey(apiKey: string) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .deleteApiKeysApiKey(apiKey)
        .then(() => {
          logger.trace("ApiKeys action deleteApiKey then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("ApiKeys action deleteApiKey catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * リストを更新する
   * @param {Array<ApiKeysGetResponse>} apiKeys リスト
   */
  @Mutation
  updateList(apiKeys: Array<ApiKeysGetResponse>) {
    logger.trace("ApiKeys mutation updateList", apiKeys);
    this._apiKeys = apiKeys;
  }

  /**
   * ユーザー詳細を更新する
   * @param {Array<ApiKeysGetResponse>} apiKeys ユーザー情報
   */
  @Mutation
  updateUserDetail(apiKeys: ApiKeysGetResponse) {
    logger.trace("ApiKeys mutation updateUserDetail", apiKeys);
    this._apiKeysDetail = apiKeys;
  }
}

export const ApiKeysModule = getModule(ApiKeys);
