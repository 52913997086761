import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { logger } from "@/logger";
import { ContactPostRequest } from "generated/openapi-generator";
import { EtcModule } from "@/store/module/etc";

@Component({ components: { ValidationObserver, ValidationProvider } })
/**
 * お問い合わせフォーム
 */
export default class ContactForm extends Vue {
  name = "";
  email = "";
  contents = "";

  @Prop({ type: Boolean, default: false })
  showDialog: boolean | undefined;

  /**
   * キャンセルボタンクリック時の処理
   */
  onClickCancel(): void {
    this.$emit("clickClose");
  }

  /**
   * 送信ボタンクリック時の処理
   */
  async onClickSend(): Promise<void> {
    EtcModule.dummy;
    await this.$recaptchaLoaded();
    const recaptchaToken = await this.$recaptcha("contact");
    logger.trace("recaptchaToken is", recaptchaToken);
    const request: ContactPostRequest = {
      name: this.name,
      email: this.email,
      contents: this.contents,
      recaptchaToken,
    };
    this.$store.dispatch("etc/postContact", request).finally(() => {
      logger.trace("postContact finished.");
    });
    this.$emit("clickClose");
  }
}
