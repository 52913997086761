import { Configuration, PublicApi, LoginInfo, SignupInfo, MemberApi, PasswordRemind, ChangePassword } from "../../../generated/openapi-generator";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import CommonUtil from "@/utils/commonUtil";
import { OverlayDecorator } from "../utils";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IAuthState {
  isLogined: boolean;
  permissionList: Record<string, unknown>;
  fullOverlay: boolean;
  snackbarInfo: Record<string, unknown>;
}

/**
 * 認証用のストアモジュール
 */
@Module({ dynamic: true, store, name: "auth", namespaced: true })
class Auth extends VuexModule implements IAuthState {
  _isLogined = false;
  _isAdmin = false;
  _permissionList: Record<string, unknown> = {};
  _fullOverlay = false;
  _snackbarInfo: Record<string, unknown> = {};
  _userId = "";

  /**
   * ログイン済みかどうか
   */
  get isLogined() {
    return this._isLogined;
  }

  /**
   * 管理者かどうか
   */
  get isAdmin() {
    return this._isAdmin;
  }

  /**
   * 権限リスト
   */
  get permissionList() {
    return this._permissionList;
  }

  /**
   * オーバーレイを表示するかどうか
   */
  get fullOverlay() {
    return this._fullOverlay;
  }

  /**
   * Snackbar情報
   */
  get snackbarInfo() {
    return this._snackbarInfo;
  }

  /**
   * ユーザーID
   */
  get userId() {
    return this._userId;
  }

  /**
   * ログイン済みかどうかを変更する
   * @param {any} data 変更内容
   */
  @Mutation
  changeLogined(data: { isLogined: boolean; permissionList?: Record<string, unknown>; isAdmin: boolean; userId: string }) {
    logger.trace("data is", data);
    this._isLogined = data.isLogined;
    this._isAdmin = data.isAdmin;
    this._userId = data.userId;
    if ("permissionList" in data && data.permissionList) {
      this._permissionList = data.permissionList;
    }
  }

  /**
   * オーバーレイの表示状態を変更する
   * @param {boolean} fullOverlay 変更内容
   */
  @Mutation
  changeFullOverlay(fullOverlay: boolean) {
    logger.trace("changeFullOverlay", fullOverlay);
    this._fullOverlay = fullOverlay;
  }

  /**
   * Snackbarの情報を変更する
   * @param {any} data 変更内容
   */
  @Mutation
  changeSnackbarInfo(data: Record<string, unknown>) {
    this._snackbarInfo = data;
  }

  /**
   * ログインアクション
   * @param {LoginInfo} loginInfo ログイン情報
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  login(loginInfo: LoginInfo) {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .postAuthLogin(loginInfo)
        .then((response) => {
          logger.trace("actions login then");
          this.context.commit("changeLogined", {
            isLogined: true,
            permissionList: response.data.permissionAssociativeArray,
            isAdmin: response.data.isAdmin,
            userId: response.data.userId,
          });
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions login catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * ログアウトアクション
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  logout() {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .getAuthLogout()
        .then(() => {
          logger.trace("actions logout then");
          this.context.commit("changeLogined", { isLogined: false });
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions logout catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * ログイン状態確認アクション
   * @return {Promise<void>} promise
   */
  @Action({ rawError: true })
  @OverlayDecorator()
  checkLogined() {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .getAuthIsLogined()
        .then((response) => {
          logger.trace("actions checkLogined then", response.data);
          this.context.commit("changeLogined", {
            isLogined: true,
            permissionList: response.data.permissionAssociativeArray,
            isAdmin: response.data.isAdmin,
            userId: response.data.userId,
          });
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions checkLogined catch", reason);
          this.context.commit("changeLogined", { isLogined: false });
          reject(reason);
        });
    });
  }

  /**
   * サインアップアクション
   * @param {SignupInfo} signupInfo
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  signup(signupInfo: SignupInfo) {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .postAuthSignup(signupInfo)
        .then(() => {
          logger.trace("actions signup then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions signup catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * メール認証アクション
   * @param {string} token
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  mailAuthenticate(token: string) {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .postAuthMailAuthenticate({ token })
        .then(() => {
          logger.trace("actions mailAuthenticate then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions mailAuthenticate catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * 退会アクション
   * @param {string} token
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  leave() {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .postAuthLeave()
        .then(() => {
          logger.trace("actions leave then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions leave catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * パスワードリマインドアクション
   * @param {PasswordRemind} passwordRemind
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  passwordRemind(passwordRemind: PasswordRemind) {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .postAuthPasswordRemind(passwordRemind)
        .then(() => {
          logger.trace("actions passwordRemind then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions passwordRemind catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * パスワード変更アクション
   * @param {ChangePassword} changePassword
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  passwordChange(changePassword: ChangePassword) {
    return new Promise<void>((resolve, reject) => {
      new PublicApi(conf)
        .putAuthPassword(changePassword)
        .then(() => {
          logger.trace("actions passwordChange then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("actions passwordChange catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * オーバーレイ状態変更アクション
   * @param {boolean} flug 変更内容
   * @return {boolean} commit内容
   */
  @Action({ commit: "changeFullOverlay" })
  setFullOverlay(flug: boolean) {
    return flug;
  }

  /**
   * Snackbar表示アクション
   * @param {any} data 変更内容
   * @return {any} commit内容
   */
  @Action({ commit: "changeSnackbarInfo" })
  showSnackbar(data: { text: string; timeout: number }) {
    return data;
  }
}

export const AuthModule = getModule(Auth);
