import { Vue, Component } from "vue-property-decorator";
import { logger } from "@/logger";
import PasswordChangeForm from "@/components/passwordChangeForm/PasswordChangeForm.vue";

@Component({ components: { PasswordChangeForm } })
/**
 * 登録完了画面
 */
export default class ChangePassword extends Vue {
  showPasswordChangeForm = true;

  /**
   * ダイアログを閉じた時の処理
   */
  onCloseDialog(): void {
    this.showPasswordChangeForm = false;
    this.$router.push("login");
  }
}
