import Vue from "vue";
import VueRouter from "vue-router";
import Top from "@/views/top/Top.vue";
import Login from "@/views/login/Login.vue";
import Signup from "@/views/signup/Signup.vue";
import AuthenticationGuidance from "@/views/signup/AuthenticationGuidance.vue";
import Regist from "@/views/signup/Regist.vue";
import ChangePassword from "@/views/changePassword/ChangePassword.vue";
import KeyValues from "@/views/keyValues/KeyValues.vue";
import Users from "@/views/users/Users.vue";
import Mypage from "@/views/mypage/Mypage.vue";
import { AuthModule } from "@/store/module/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Top",
    component: Top,
    meta: { title: "トップ" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { isPublic: true, title: "ログイン" },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { isPublic: true, title: "新規登録" },
  },
  {
    path: "/authenticationguidance",
    name: "AuthenticationGuidance",
    component: AuthenticationGuidance,
    meta: { isPublic: true, title: "認証ガイド" },
  },
  {
    path: "/regist",
    name: "Regist",
    component: Regist,
    meta: { isPublic: true, title: "認証" },
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { isPublic: true, title: "パスワード変更" },
  },
  {
    path: "/keyValues",
    name: "KeyValues",
    component: KeyValues,
    meta: { title: "キーバリュー管理" },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { title: "ユーザー管理" },
  },
  {
    path: "/mypage",
    name: "Mypage",
    component: Mypage,
    meta: { title: "マイページ" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((page) => page.meta.isPublic) || AuthModule.isLogined) {
    next();
  } else {
    next({ path: "/login", query: { redirect: to.fullPath } });
  }
});

router.afterEach((to) => {
  if (to.meta) {
    // タイトルをセット
    document.title = to.meta.title ? `sapislab - ${to.meta.title}` : "sapislab";
  }
});

export default router;
