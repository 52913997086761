// eslint-disable-next-line camelcase
import { required, required_if, email, max, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "この項目は必須です",
});

extend("required_if", {
  // eslint-disable-next-line camelcase
  ...required_if,
  message: "この項目は必須です",
});

extend("max", {
  ...max,
  message: "この項目は{length}文字以下にしてください",
});

extend("min", {
  ...min,
  message: "この項目は{length}文字以上にしてください",
});

extend("email", {
  ...email,
  message: "この項目は有効なメールアドレス形式にしてください",
});
