import { Configuration, KeyValuesGetResponse, MemberApi } from "../../../generated/openapi-generator";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import { KeyValueItem } from "@/model/keyValueItem";
import CommonUtil from "@/utils/commonUtil";
import moment from "moment";
import { OverlayDecorator } from "../utils";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IKeyValuesState {
  items: Array<KeyValueItem>;
  specificItem: KeyValueItem;
}

/**
 * キーバリュー管理用のストアモジュール
 */
@Module({ dynamic: true, store, name: "keyValues", namespaced: true })
class KeyValues extends VuexModule implements IKeyValuesState {
  private _keyValues: Array<KeyValuesGetResponse> = [];
  private _keyValuesDetail: KeyValuesGetResponse = { key: "", value: "", registeredAt: undefined, updatedAt: undefined };

  /**
   * items
   */
  public get items(): Array<KeyValueItem> {
    const result = this._keyValues.reduce((prev, cur) => {
      prev.push({
        apiKey: "",
        key: cur.key!,
        value: cur.value!,
        registeredAtStr: moment.unix(cur.registeredAt ? cur.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        updatedAtStr: moment.unix(cur.updatedAt ? cur.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
        updatedAt: cur.updatedAt ? cur.updatedAt : 0,
      });
      return prev;
    }, new Array<KeyValueItem>());
    return result;
  }

  /**
   * specificItem
   */
  public get specificItem(): KeyValueItem {
    return {
      apiKey: "",
      key: this._keyValuesDetail.key!,
      value: this._keyValuesDetail.value!,
      registeredAtStr: moment.unix(this._keyValuesDetail.registeredAt ? this._keyValuesDetail.registeredAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      updatedAtStr: moment.unix(this._keyValuesDetail.updatedAt ? this._keyValuesDetail.updatedAt : 0).format("YYYY-MM-DD HH:mm:ssZ"),
      updatedAt: this._keyValuesDetail.updatedAt ? this._keyValuesDetail.updatedAt : 0,
    };
  }

  /**
   * getListアクション
   * @param {string} apiKey
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getList(apiKey: string) {
    logger.trace("KeyValues action getList");

    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .getKeyValues(apiKey)
        .then((response) => {
          logger.trace("KeyValues action getList then");
          this.context.commit("updateList", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("KeyValues action getList catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * getKeyValueアクション
   * @param {KeyValueItem} keyValueItem
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getKeyValue(keyValueItem: KeyValueItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .getKeyValuesKey(keyValueItem.key, keyValueItem.apiKey)
        .then((response) => {
          logger.trace("KeyValues action getUser then");
          this.context.commit("updateKeyValueDetail", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("KeyValues action getUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * キーバリュー作成
   * @param {KeyValueItem} keyValueItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  createKeyValue(keyValueItem: KeyValueItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .postKeyValues(keyValueItem.apiKey, { key: keyValueItem.key, value: keyValueItem.value })
        .then(() => {
          logger.trace("KeyValues action createUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("KeyValues action createUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * キーバリュー更新
   * @param {KeyValueItem} keyValueItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  updateKeyValue(keyValueItem: KeyValueItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .putKeyValuesKey(keyValueItem.key, keyValueItem.apiKey, { value: keyValueItem.value, updatedAt: keyValueItem.updatedAt })
        .then(() => {
          logger.trace("KeyValues action updateUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("KeyValues action updateUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * キーバリュー削除
   * @param {KeyValueItem} keyValueItem
   * @return {Promise<void>}
   */
  @Action
  @OverlayDecorator()
  deleteKeyValue(keyValueItem: KeyValueItem) {
    return new Promise<void>((resolve, reject) => {
      new MemberApi(conf)
        .deleteKeyValuesKey(keyValueItem.key, keyValueItem.apiKey)
        .then(() => {
          logger.trace("KeyValues action deleteUser then");
          resolve();
        })
        .catch((reason) => {
          logger.trace("KeyValues action deleteUser catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
  }

  /**
   * リストを更新する
   * @param {Array<KeyValuesGetResponse>} keyValues リスト
   */
  @Mutation
  updateList(keyValues: Array<KeyValuesGetResponse>) {
    logger.trace("KeyValues mutation updateList", keyValues);
    this._keyValues = keyValues;
  }

  /**
   * ユーザー詳細を更新する
   * @param {Array<KeyValuesGetResponse>} keyValue ユーザー情報
   */
  @Mutation
  updateKeyValueDetail(keyValue: KeyValuesGetResponse) {
    logger.trace("KeyValues mutation updateKeyValueDetail", keyValue);
    this._keyValuesDetail = keyValue;
  }
}

export const KeyValuesModule = getModule(KeyValues);
