import { AdminApi, RolesGetResponse, Configuration } from "../../../generated/openapi-generator";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { logger } from "@/logger";
import store from "@/store";
import { RoleItem } from "@/model/roleItem";
import CommonUtil from "@/utils/commonUtil";
import { OverlayDecorator } from "../utils";

const conf = new Configuration({
  basePath: process.env.VUE_APP_API_SERVER,
});

export interface IRolesState {
  items: Array<RoleItem>;
  specificItem: RoleItem;
}

/**
 * キーバリュー管理用のストアモジュール
 */
@Module({ dynamic: true, store, name: "roles", namespaced: true })
class Roles extends VuexModule implements IRolesState {
  private _Roles: Array<RolesGetResponse> = [];
  private _RolesDetail: RolesGetResponse = {};

  /**
   * items
   */
  public get items(): Array<RoleItem> {
    const result = this._Roles.reduce((prev, cur) => {
      prev.push({
        name: cur.roleName!,
      });
      return prev;
    }, new Array<RoleItem>());
    return result;
  }

  /**
   * specificItem
   */
  public get specificItem(): RoleItem {
    return {
      name: this._RolesDetail.roleName!,
    };
  }

  /**
   * getListアクション
   * @return {Promise<void>} promise
   */
  @Action
  @OverlayDecorator()
  getList() {
    logger.trace("Roles action getList");

    return new Promise<void>((resolve, reject) => {
      new AdminApi(conf)
        .getRoles()
        .then((response) => {
          logger.trace("Roles action getList then");
          this.context.commit("updateList", response.data);
          resolve();
        })
        .catch((reason) => {
          logger.trace("Roles action getList catch", reason);
          CommonUtil.errorHandling(this, reason);
          reject(reason);
        });
    });
    // this.context.commit("updateList", [{ name: "test01" }, { name: "test02" }]);
  }

  // /**
  //  * getDetailアクション
  //  * @param {RoleItem} RoleItem
  //  * @return {Promise<void>} promise
  //  */
  // @Action
  // getDetail(RoleItem: RoleItem) {
  //   this.context.dispatch("auth/setFullOverlay", true, { root: true });
  //   return new Promise<void>((resolve, reject) => {
  //     new MemberApi(conf)
  //       .getRolesKey(RoleItem.key, RoleItem.apiKey)
  //       .then((response) => {
  //         logger.trace("Roles action getDetail then");
  //         this.context.commit("updateKeyValueDetail", response.data);
  //         resolve();
  //       })
  //       .catch((reason) => {
  //         logger.trace("Roles action getDetail catch", reason);
  //         CommonUtil.errorHandling(this, reason);
  //         reject(reason);
  //       })
  //       .finally(() => {
  //         logger.trace("Roles action getDetail finally");
  //         this.context.dispatch("auth/setFullOverlay", false, { root: true });
  //       });
  //   });
  // }

  /**
   * リストを更新する
   * @param {Array<RolesGetResponse>} Roles リスト
   */
  @Mutation
  updateList(Roles: Array<RolesGetResponse>) {
    logger.trace("Roles mutation updateList", Roles);
    this._Roles = Roles;
  }

  /**
   * 詳細を更新する
   * @param {Array<RolesGetResponse>} keyValue ユーザー情報
   */
  @Mutation
  updateDetail(keyValue: RolesGetResponse) {
    logger.trace("Roles mutation updateDetail", keyValue);
    this._RolesDetail = keyValue;
  }
}

export const RolesModule = getModule(Roles);
